<template>
  <div class="numbers">
    <div class="bg-circle"></div>

    <LineInfo title="Структура бизнес-модели" />

    <b-container class="numbers-container">
      <b-row>
        <b-col cols="12" md="4" v-for="(item, index) in data" :key="index">
          <div class="numbers-block">
            <b-row class="numbers-block_img-canvas">
              <div class="numbers-block_img-circle">
                <p class="numbers-value">{{ item.num }}</p>
              </div>
            </b-row>

            <b-row align-h="center" align-v="start">
              <div class="numbers-block_text-canvas">
                <h4 class="numbers-block_title">{{ item.title }}</h4>

                <p class="numbers-block_text">{{ item.text }}</p>
              </div>
            </b-row>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="numbers-subtitle">
            <h3 class="italic_accent__subtitle">
              С-МАТЕРИАЛС - это экосистема для реализации практически любых<br />
              тендерных поставок.
            </h3>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";

export default {
  name: "Numbers",
  components: {
    LineInfo,
  },
  data() {
    return {
      data: [
        {
          num: "01",
          title: "Заказчик",
          text: `
          Крупный бизнес, которому требуется поставить товары или оказать услуги.
          `,
        },
        {
          num: "02",
          title: "С-МАТЕРИАЛС",
          text: "Компания, обладающая всей инфраструктурой, опытом, человеческим и финансовым ресурсом для успешной реализации данной закупки, используя потенциал партнерской сети.",
        },
        {
          num: "03",
          title: "Партнерская сеть",
          text: "Проверенные поставщики и производители, у которых есть необходимый товар или которые могут выполнить необходимые работы, соответствующие заявленному качеству и характеристикам.",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.numbers {
  padding-top: 150px;
  padding-bottom: 180px;
  position: relative;
}
.numbers-container {
  padding-top: 80px;
  margin-top: 30px;
}
.bg-circle {
  border-radius: 100%;
  border-color: rgba($accent, 0.2);
  border-width: 40px;
  border-style: solid;

  height: 90vh;
  width: 90vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.numbers-block {
  background-color: $gray;

  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: $gray-light;
  height: 300px;
}
.numbers-block_img-canvas {
  height: 80px;
  position: relative;

  margin-bottom: 30px;
}
.numbers-block_img-circle {
  height: 160px;
  width: 160px;

  border-radius: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba($accent, 0.9);
}
.numbers-value {
  text-transform: uppercase;
  font-size: 90px;
  font-weight: 300;
  color: $white;
  margin: 0;
}

.numbers-block_text-canvas {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 15px;
}
.numbers-block_title {
  text-align: center;
  color: $white;
  font-size: 20px;
  font-weight: 500;
}
.numbers-block_text {
  text-align: center;
  color: $white;
  font-size: 14px;
  font-weight: 300;
}

.numbers-subtitle {
  background-color: $gray-dark;
  border-width: 1px;
  border-color: $gray-light;
  border-style: solid;
  border-radius: 10px;
  padding: 30px 10% 30px 10%;
  margin-top: 30px;
}
</style>
