<template>
  <div id="bussinesModel">
    <img id="cloudHeader" :src="require('@/assets/img/cloud.png')" alt="" />

    <div class="navbar-canvas"></div>

    <HeaderLogo
      title="Наш взгляд на рынок тендеров"
      showLogoTitle
      text="Работа на рынке тендеров сложна, ответственна, многокомпонентна. Здесь случается всякое: многие компании не рассматривают аукционы как долгосрочный бизнес и пренебрегают качеством; заказчики сталкиваются с посредственностью, безучастностью или непрофессионализмом исполнителей. Команда С-МАТЕРИАЛС работает иначе. Мы понимаем, как устроен рынок, какие задачи могут возникнуть на любом этапе закупки; мы коммуницируем с заказчиком на протяжении всего аукциона, решаем срочные вопросы, выполняем свою работу качественно, со знанием дела и в срок. 
      "
    />

    <!-- <BusinessSide /> -->

    <img id="cloudBMtwo" :src="require('@/assets/img/cloud.png')" alt="" />
    <LineInfo
      title="Особенности бизнес-модели,<br /> основанной на партнерстве и сервисе"
      description='Опыт и ресурсы "С-МАТЕРИАЛС" - ценность для рынка'
    />

    <div class="features">
      <b-container>
        <b-row class="left">
          <b-col cols="12" md="6">
            <div class="features_img-canvas">
              <img
                class="features_img"
                :src="require('@/assets/photo/_S__3094.jpg')"
                alt=""
              />
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <b-row align-v="center" align-h="center" class="featuers-block">
              <b-col cols="12" md="10" class="features-block_text-canvas">
                <h3 class="features-block_title">Понимание процессов</h3>

                <p class="features-block_text">
                  Мы глубоко понимаем процессы каждого этапа тендерных поставок,
                  так как обладаем опытом, измеряющимся успешно реализованными
                  контрактами на сумму в более 10 миллиардов рублей за последнее
                  10 лет.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="right">
          <b-col cols="12" md="6">
            <b-row align-v="center" align-h="center" class="featuers-block">
              <b-col cols="12" md="10" class="features-block_text-canvas">
                <h3 class="features-block_title">Инфраструктура</h3>

                <p class="features-block_text">
                  Основываясь на собственном опыте, мы создали инфраструктуру,
                  которая позволяет успешно решать даже самые нестандартные
                  задачи заказчика за счёт безграничного ресурса производителей
                  качественных товаров и услуг.
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="6">
            <div class="features_img-canvas">
              <img
                class="features_img"
                :src="require('@/assets/photo/_S__3556.jpg')"
                alt=""
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="last">
          <b-col cols="12" md="6">
            <div class="features_img-canvas">
              <img
                class="features_img"
                :src="require('@/assets/img/bussines_model/three.jpg')"
                alt=""
              />
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <b-row align-h="center" align-v="center" class="featuers-block">
              <b-col cols="12" md="10" class="features-block_text-canvas">
                <h3 class="features-block_title">Широкие возможности</h3>

                <p class="features-block_text">
                  С-МАТЕРИАЛС - «Амазон» в сфере тендерного снабжения. У нас
                  есть прямой и быстрый доступ к более 1000 категорий товаров и
                  услуг, которые соответствуют требованиям заказчика.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <Numbers />

    <LineInfo
      title="Фундамент нашей бизнес-модели "
      description="Довольный заказчик - критерий нашей эффективности."
    />

    <div class="bm-efi_canvas">
      <img
        id="cloud-bm-effi_one"
        :src="require('@/assets/img/cloud.png')"
        alt=""
      />
      <img
        id="cloud-bm-effi_two"
        :src="require('@/assets/img/cloud.png')"
        alt=""
      />

      <b-container class="bm-effi">
        <b-row class="left" align-v="center">
          <b-col cols="12" md="5">
            <div class="bm-effi_img-canvas">
              <img
                class="bm-effi_img"
                :src="require('@/assets/photo/_S__3303.jpg')"
                alt=""
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            md="7"
            class="featuers-block d-flex align-items-center"
          >
            <b-row align-h="center" align-v="center">
              <b-col cols="12" md="10" class="bm-effi-block_text-canvas">
                <h3 class="bm-effi-block_title">
                  Понимаем внутренние процессы и потребности заказчика
                </h3>
                <p
                  class="bm-effi-block_text text_popup_trigger"
                  @click="showHideText(ten)"
                >
                  Руководители компании накопили 10-летний опыт на рынке
                  тендерного снабжения как со стороны заказчика, так и со
                  стороны поставщика. Отлично разбираются в каждом процессе
                  изнутри.
                </p>
                <p class="popup_text" v-if="ten.show" v-html="ten.txt"></p>

                <b-row align-h="start">
                  <b-col cols="auto">
                    <button
                      @click="setActive(0)"
                      v-b-modal.modal_text
                      id="button_outline"
                    >
                      Подробнее
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="right" align-v="center" align-h="center">
          <b-col cols="12" md="7" class="featuers-block">
            <b-row align-v="center" align-h="center">
              <b-col cols="12" md="10" class="bm-effi-block_text-canvas">
                <h3 class="bm-effi-block_title">
                  Ведём непрерывную коммуникацию<br />
                  с заказчиком
                </h3>
                <p class="bm-effi-block_text">
                  Выстраиваем долгосрочные отношения с каждым заказчиком,
                  поэтому всегда на связи и готовы приступить к выполнению даже
                  срочных задач.
                </p>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="5">
            <div class="bm-effi_img-canvas">
              <img
                class="bm-effi_img"
                :src="require('@/assets/img/bussines_model/phone.png')"
                alt=""
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="last" align-v="center" align-h="center">
          <b-col cols="12" md="5">
            <div class="bm-effi_img-canvas">
              <img
                class="bm-effi_img"
                :src="require('@/assets/photo/_S__3108.jpg')"
                alt=""
              />
            </div>
          </b-col>

          <b-col cols="12" md="7" class="featuers-block">
            <b-row align-h="center" align-v="center">
              <b-col cols="12" md="10" class="bm-effi-block_text-canvas">
                <h3 class="bm-effi-block_title">
                  Держим фокус на автоматизации
                </h3>

                <p class="bm-effi-block_text">
                  Автоматизируем необходимые процессы для повышения качества
                  оказываемых услуг. Такой подход позволяет нам:<br />
                  - сокращать сроки поставок;<br />
                  - делать каждый этап исполнения контракта прозрачным;<br />
                  - осуществлять непрерывный контроль качества и сроков;<br />
                  - упростить и ускорить коммуникацию с заказчиками;<br />
                  - эффективно и быстро развивать компетенции сотрудников.<br />
                </p>

                <b-row align-h="start">
                  <b-col cols="auto">
                    <button
                      @click="setActive(1)"
                      v-b-modal.modal_text
                      id="button_outline"
                    >
                      Еще преимущества
                    </button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <b-modal
        id="modal_text"
        size="lg"
        hide-footer
        hide-header
        body-class="partner_list__body"
        modal-class="partner_list__modal"
        dialog-class="partner_list__dialog"
        centered
        content-class="partner_list__content"
      >
        <b-container>
          <button class="partner_modal__close" type="button" @click="close">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 5L5 19M5.00001 5L19 19"
                stroke="#fff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>

          <b-row align-h="center">
            <b-col cols="12" md="10">
              <div
                class="aditional_modal__data_wrapper"
                v-for="(item, index) in modal[active].data"
                :key="index"
              >
                <b
                  class="aditional_modal__title"
                  v-html="
                    modal[active].params.pagination
                      ? index + 1 + '. ' + item.title
                      : item.title
                  "
                ></b>

                <p class="aditional_modal__text" v-html="item.text"></p>
              </div>

              <p
                class="aditional_modal__description"
                v-if="modal[active].description"
                v-html="modal[active].description"
              ></p>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </div>

    <div class="bm_rictanglePartner_canvas">
      <img id="bm_rictanglePartner" src="@/assets/img/rictangle.png" alt="" />
      <PartnerCards />
    </div>

    <ContactForm />

    <!-- <News /> -->
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";
import News from "@/components/News.vue";
import ContactForm from "@/components/ContactForm.vue";
import PartnerCards from "@/components/SwiperCardsLogo.vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import Numbers from "@/components/Numbers.vue";
import BusinessSide from "@/components/BusinessSide.vue";

export default {
  name: "BussinesModel",
  components: {
    LineInfo,
    News,
    ContactForm,
    PartnerCards,
    HeaderLogo,
    Numbers,
    BusinessSide,
  },
  data() {
    return {
      ten: {
        show: false,
        txt: `Константин Миронов – 13-летний опыт в тендерных поставках:<br>
        <b>2010 - 2018 г.</b> – Дочерняя компания ПАО «НК Роснефть». Прошёл путь от сотрудника до руководителя проекта строительства систем автоматизации и связи.<br />
        <br />
        <b>2018 - 2022 г.</b> – Учредитель и директор компании по тендерному сопровождению ООО "Тендерикс".<br />
        <br />
        <b>В настоящее время</b> – Соавтор и ведущий обучающей программы для предпринимателей «Крупные сделки», соучредитель и директор ООО «С-Материалс» (тендерное снабжение крупного бизнеса по всей России).
        `,
      },

      modal: [
        {
          params: {
            pagination: false,
          },
          data: [
            {
              title:
                "Константин Миронов – 13-летний опыт в тендерных поставках:",
              text: `
              <b>2010 - 2018 г.</b> – Дочерняя компания ПАО «НК Роснефть». Прошёл путь от сотрудника до руководителя проекта строительства систем автоматизации и связи.<br />
              <br />
              <b>2018 - 2022 г.</b> – Учредитель и директор компании по тендерному сопровождению ООО "Тендерикс".<br />
              <br />
              <b>В настоящее время</b> – Соавтор и ведущий обучающей программы для предпринимателей «Крупные сделки», соучредитель и директор ООО «С-Материалс» (тендерное снабжение крупного бизнеса по всей России).
              `,
            },
          ],
          description: "",
        },
        {
          params: {
            pagination: true,
          },
          data: [
            {
              title: "Работаем на опережение и берёмся за срочность.",
              text: `
              Профессионально подходим к формированию потребности заказчика на этапе планирования закупки, что позволяет поставлять качественный товар. Готовы решать срочные задачи наших клиентов.
              `,
            },
            {
              title: "Принимаем быстрые решения и упрощаем процессы.",
              text: `
              Время - ценный ресурс, и только действия приводят к результату. Мы оптимизируем бизнес-процессы в сторону упрощения, снижая бюрократическую волокиту.
              `,
            },
            {
              title: "Берем ответственность.",
              text: `
              Предоставляем отсрочку платежа до 60 дней. Берём на себя вопросы по хранению товара, а также замораживаем стоимость на длительный срок. Решаем задачи заказчика, связанные с подготовкой документов, так как обладаем опытом, который позволяет это сделать быстро.
              `,
            },
            {
              title: "Создаём спокойную среду.",
              text: `
              Выстраиваем доверительные, прозрачные отношения. Каждому заказчику предоставляем доступ в личный кабинет, где в режиме реального времени можно следить за статусом исполнения контракта. Наш опыт и отлаженные процессы внутри компании позволяют упростить работу заказчика и забрать на себя решение рутинных задач.
              `,
            },
            {
              title: "Формируем уверенность.",
              text: `
              Каждое наше действие и принятое решение формирует образ бренда компании. Поэтому мы работаем качественно и профессионально.
              Практический опыт команды измеряется успешно исполненными контрактами на сумму 10 миллиардов рублей. Компетенции каждого участника команды гарантируют: если мы взялись за дело, то справимся и не подведем. Мы обладаем практическим опытом, точно знаем свою работу, справляемся с самыми нестандартными задачами.
              `,
            },
            {
              title: "Проявляем инициативу.",
              text: `
              Действуем на опережение. Включаемся на самых ранних этапах, в фазе планирования. Обращаем внимание на мелочи, устраняем проблемы до того, как они случились. Помогаем заказчику выбрать оптимальные варианты решения задачи.
              `,
            },
          ],
        },
      ],
      active: 0,
    };
  },
  methods: {
    showHideText(popObj) {
      popObj.show = !popObj.show;
    },
    close() {
      this.$bvModal.hide("modal_text");
    },
    setActive(i) {
      this.active = i;
    },
  },
};
</script>

<style lang="scss" scoped>
#bussinesModel {
  overflow: hidden;
  position: relative;
}
#cloudHeader {
  position: absolute;
  height: 70vh;
  left: 40%;
  top: -8%;
  transform: translate(-50%, 0);
}
#cloudBMtwo {
  position: absolute;
  height: 60vh;
  left: 60%;
  top: 23%;
  transform: translate(-50%, -50%);
}
.bm-efi_canvas {
  position: relative;
}
#cloud-bm-effi_one {
  position: absolute;
  height: 70vh;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
#cloud-bm-effi_two {
  position: absolute;
  height: 70vh;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}
.bm_rictanglePartner_canvas {
  position: relative;
}
#bm_rictanglePartner {
  position: absolute;
  z-index: -1;

  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);

  height: 140vh;
}

.bussines-model {
  position: relative;
}
/* header-page */
.header-page {
  min-height: 60vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.page-title {
  text-align: center;
  color: $white;
  font-weight: 700;
  font-size: 30px;
  text-transform: uppercase;

  padding-bottom: 15px;
}

/* features */
.features {
  position: relative;
  z-index: 2;
  margin-bottom: 100px;
  background-color: $gray;
}
.left .features_img-canvas {
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 0 5px 120px 0;
}
.right .features_img-canvas {
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 5px 0 0 120px;
}
.last .features_img-canvas {
  height: 320px;
  position: relative;
  overflow: hidden;
  border-radius: 0 120px 5px 0;
}
.features_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}
.features-block {
  background-color: $gray;
}
.features-block_text-canvas {
  padding-left: 30px;
  padding-right: 30px;
}
.features-block_title {
  color: $accent;
  font-size: 20px;
  font-weight: 700;
}
.features-block_text {
  color: $white;
  font-size: 16px;
  font-weight: 400;
}

/* bm-effi */
/* features */
.bm-effi {
  padding-bottom: 100px;
}
.left .bm-effi_img-canvas {
  height: 340px;
  position: relative;
  overflow: hidden;
  border-radius: 0 5px 120px 0;
}
.right .bm-effi_img-canvas {
  height: 340px;
  position: relative;
  overflow: hidden;
  border-radius: 5px 0 0 120px;
}
.last .bm-effi_img-canvas {
  height: 340px;
  position: relative;
  overflow: hidden;
  border-radius: 0 120px 5px 0;
}
.bm-effi_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
}
.bm-effi-block {
  background-color: $gray;
}
.bm-effi-block_text-canvas {
  padding-left: 30px;
  padding-right: 30px;
}
.bm-effi-block_title {
  color: $accent;
  font-size: 20px;
  font-weight: 700;
  padding-top: 15px;
}
.bm-effi-block_text {
  color: $white;
  font-size: 16px;
  font-weight: 400;
}

.featuers-block {
  height: 100%;
}

.aditional_modal__title {
  color: $white;
  font-size: 18px;
  font-weight: 400;
}
.aditional_modal__text {
  font-size: 16px;
  color: $gray__ultra_light;
  font-weight: 300;
}
.aditional_modal__description {
  color: $gray__medium_light;
  font-size: 18px;
  font-weight: 300;
  padding-top: 30px;
  font-style: italic;
  margin: 0;
}
</style>
