<template>
  <div>
    <b-container>
      <b-row align-h="between" class="bm_swipers">
        <b-col cols="12" md="6" class="bm_swiper__wrapper">
          <div class="sliders_bm__content">
            <div v-swiper:mySwiperApl="swiperOption">
              <div class="swiper-wrapper bm">
                <div
                  class="swiper-slide"
                  v-for="(item_data, index) in manufacturer"
                  :key="index"
                >
                  <div class="bm_slide__block">
                    <b-row align-h="center" align-v="start">
                      <div class="img_bm__wrapper">
                        <img
                          class="img_bm"
                          :src="
                            require('@/assets/img/bussines_model/' +
                              item_data.img)
                          "
                          alt=""
                        />
                      </div>
                    </b-row>

                    <b-row align-v="start" align-h="center">
                      <div class="slider-text-block">
                        <h3 class="self-block_title">{{ item_data.title }}</h3>

                        <p class="self-block_text">{{ item_data.text }}</p>
                      </div>
                    </b-row>
                  </div>
                </div>
              </div>

              <div class="swiper-pagination"></div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="6" class="bm_swiper__wrapper">
          <div class="sliders_bm__content">
            <div v-swiper:mySwiperAplTwo="swiperOption">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item_data, index) in customer"
                  :key="index"
                >
                  <div class="bm_slide__block">
                    <b-row align-h="center" align-v="start">
                      <div class="img_bm__wrapper">
                        <img
                          class="img_bm"
                          :src="
                            require('@/assets/img/bussines_model/' +
                              item_data.img)
                          "
                          alt=""
                        />
                      </div>
                    </b-row>

                    <b-row align-v="start" align-h="center">
                      <div class="slider-text-block">
                        <h3 class="self-block_title">{{ item_data.title }}</h3>

                        <p class="self-block_text">{{ item_data.text }}</p>
                      </div>
                    </b-row>
                  </div>
                </div>
              </div>

              <div class="swiper-pagination"></div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "BusinessSide",
  data() {
    return {
      manufacturer: [
        {
          img: "1.png",
          title: "Производители",
          text: `
          Многие компании не знают о возможностях рынка тендеров, поэтому их там нет.
          `,
        },
        {
          img: "2.png",
          title: "Производители",
          text: `
          Многие компании думают, что тендеры - это головная боль и участвовать в них могут только “свои”.
          `,
        },
        {
          img: "3.png",
          title: "Производители",
          text: `
          Многие компании не соответствуют стандартам, предъявляемым крупными заказчиками.
          `,
        },
        {
          img: "7.jpg",
          title: "Производители",
          text: `
          Многие компании не могут обеспечить большие объёмы закупок, так как у них нет в обороте свободных денежных средств или товара.
          `,
        },
        {
          img: "8.jpg",
          title: "Производители",
          text: `
          Многие компании не рассматривают тендерные поставки как долгосрочный бизнес, поэтому пренебрегают качеством.
          `,
        },
      ],
      customer: [
        {
          img: "4.png",
          title: "Заказчики",
          text: `
          Заказчики сталкиваются с посредственностью в работе исполнителей, когда работа выполняется спустя рукава, под лозунгом  “ай, так сойдёт”.
          `,
        },
        {
          img: "5.png",
          title: "Заказчики",
          text: `
          Заказчики сталкиваются с безучастностью исполнителя, когда речь заходит о решении  задач, выходящих за рамки подписанного договора, например, требуется заменить позицию в смете или поставить товар раньше срока и т. п.
          `,
        },
        {
          img: "6.png",
          title: "Заказчики",
          text: `
          Заказчики сталкиваются с непрофессионализмом исполнителей, когда приходится переделывать и исправлять ошибки, когда нарушаются сроки поставок.
          `,
        },
        {
          img: "9.jpg",
          title: "Заказчики",
          text: `
          Заказчики сталкиваются с безответственность исполнителей при решении задач, например, невнимательность и халатность, стратегия урвать здесь и сейчас, пренебрегая долгосрочными отношениями.
          `,
        },
      ],
      swiperOption: {
        cssMode: true,
        mousewheel: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss">
.sliders__border {
  position: absolute;
  bottom: 0;
  border: 1px solid $gray-light;
  background-color: $gray;
  height: 80%;
  width: 100%;
}
@media only screen and (max-width: 640px) {
  .sliders__border_left {
    border-width: 1px !important;
  }
}
.sliders__border_left {
  position: absolute;
  bottom: 0;
  border: solid $gray-light;
  border-width: 1px 0 1px 1px;
  background-color: $gray;
  height: 80%;
  width: 100%;
}
.sliders_bm__content {
  overflow: hidden;
  border-radius: 15px;
  height: 100%;
  background-color: $gray;
  border: 1px solid rgba($color: $gray-light, $alpha: 0.9);
  position: relative;
  z-index: 2;
}

.swiper-pagination {
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.img_bm__wrapper {
  height: 325px;
  width: 100%;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.img_bm {
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
}

.slider-text-block {
  padding-left: 10%;
  padding-right: 10%;
}
.self-block_title {
  color: $white;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
}
.self-block_text {
  color: $white;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  min-height: 120px;
}
.bm_swipers {
  margin-bottom: 100px;
}

.bm_swiper__wrapper {
  margin-bottom: 30px;
}
</style>
